import { css } from "@emotion/css";

export const PoolTickStyles = {
  content: css`
    display: flex;
  `,
  imageBlock: css`
    display: flex;
    margin-right: 8px;
  `,
  imageWrapper: css`
    max-height: 40px;
    border-radius: 32px;
  `,
  textBlock: css`
    font-family: sans-serif;
    font-size: 16px;
    padding-top: 8px;
  `,
  href: css`
    color: black;
  `,
};
