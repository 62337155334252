import { css } from "@emotion/css";

export const TraderMenuStyles = {
  AddressAndButtonBlock: css`
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  `,
  goBackButtonContainer: css`
    display: flex;
  `,
  arrow: css`
    padding-top: 3px;
    margin-right: 4px;
    width: 16px;
    height: 16px;
  `,
  address: css`
    font-size: 20px;
    font-family: sans-serif;
    margin-bottom: 20px;
  `,

  statsWrapper: css`
    margin-bottom: 40px;
  `,
};
