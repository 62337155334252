import React from "react";
import { TopTraders } from "./components/TopTraders";

function App() {
  return (
    <div className="App">
      <div>
        <TopTraders></TopTraders>
      </div>
    </div>
  );
}

export default App;
