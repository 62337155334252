import {TON_DATA_API} from "../constants/urls";

interface ITrades {
    traderAddress: string;
}

export interface ITrade {
    pool_address: string;
    lt: number;
    timestamp: number;
    amount: number;
    price: number;
    ton_price: number;
    type: string;
    base_token_symbol: string;
    base_token_image: string;
    quote_token_symbol: string;
    quote_token_image: string;
    dex: string;
}

export const getTradesByTrader = async ({
                                            traderAddress,
                                        }: ITrades): Promise<ITrade[]> => {
    try {
        const answer = await fetch(`${TON_DATA_API}/trades/${traderAddress}`);
        const data = await answer.json();
        return data.result;
    } catch (error) {
        console.log("getStatsByTrader", error);
        return [];
    }
};
