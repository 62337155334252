import {TON_DATA_API} from "../constants/urls";

export interface ITopTrader {
    trader_address: string;
    unbounceable_trader_address: string;
    realized_pnl_usd: number;
    unrealized_pnl_usd: number;
    roi: number;
    trades_count: number;
    last_timestamp: number;
}

export const getTopTraders = async (): Promise<ITopTrader[]> => {
    try {
        const answer = await fetch(`${TON_DATA_API}/top_traders`);
        const data = await answer.json();
        return data.result;
    } catch (error) {
        console.log("getTopTraders", error);
        return [];
    }
};
