import {TraderMenuStyles} from "../styles/TraderMenuStyles";
import {Button, Table} from "antd";
import leftArrow from "../pics/leftArrow.png";
import {useEffect, useState} from "react";
import {getStatsByTrader, ITraderStats} from "../utils/getStatsByTrader";
import {formatNumber} from "../utils/formatNumber";
import {roundTwoSymbols} from "../utils/roundTwoSymbols";
import {PoolTick} from "./PoolTick";
import {getTradesByTrader, ITrade} from "../utils/getTradesByTrader";
import {timestampToDate} from "../utils/timestampToDate";
import {Token} from "./Token";

interface ITraderInfoMenuProps {
    traderAddress: string;
    showAddressInfo: (traderAddress: string) => void;
}

export const TraderInfoMenu = ({
                                   traderAddress,
                                   showAddressInfo,
                               }: ITraderInfoMenuProps) => {
    const [stats, setStats] = useState<ITraderStats[]>([]);
    const [statsLoading, setStatsLoading] = useState<boolean>(false);
    const [tradesLoading, setTradesLoading] = useState<boolean>(false);
    const [trades, setTrades] = useState<ITrade[]>([]);

    const getTraderStats = async () => {
        setStatsLoading(true);
        setStats([]);
        const data = await getStatsByTrader({traderAddress});
        setStats(data);
        setStatsLoading(false);
    };

    const getTrades = async () => {
        setTradesLoading(true);
        setTrades([]);
        const data = await getTradesByTrader({traderAddress});
        setTrades(data);
        setTradesLoading(false);
    };

    useEffect(() => {
        getTraderStats();
    }, []);

    useEffect(() => {
        getTrades();
    }, []);

    const traderMenuColumns = [
        {
            title: "Token",
            dataIndex: "token_address",
            key: "token_address",
            render: (data: any, record: any) => {
                return (
                    <Token
                        tokenAddress={record.token_address}
                    />
                );
            },
        },
        {
            title: "Realized PNL",
            dataIndex: "realized_pnl_usd",
            key: "realized_pnl_usd",
            sorter: (a: ITraderStats, b: ITraderStats) =>
                a.realized_pnl_usd - b.realized_pnl_usd,
            render: (data: any) => "$" + formatNumber(Math.round(data)),
        },
        {
            title: "Unrealized PNL",
            dataIndex: "unrealized_pnl_usd",
            key: "unrealized_pnl_usd",
            sorter: (a: ITraderStats, b: ITraderStats) =>
                a.unrealized_pnl_usd - b.unrealized_pnl_usd,
            render: (data: any) => "$" + formatNumber(Math.round(data)),
        },

        {
            title: "ROI",
            dataIndex: "roi",
            key: "roi",
            sorter: (a: ITraderStats, b: ITraderStats) => a.roi - b.roi,
            render: (data: any) => roundTwoSymbols(data) + "%",
        },
        {
            title: "Unrealized ROI",
            dataIndex: "unrealized_roi",
            key: "unrealized_roi",
            sorter: (a: ITraderStats, b: ITraderStats) => a.roi - b.roi,
            render: (data: any) => roundTwoSymbols(data) + "%",
        },
        {
            title: "Trades count",
            dataIndex: "trades_count",
            key: "trades_count",
            sorter: (a: ITraderStats, b: ITraderStats) =>
                a.trades_count - b.trades_count,
        },
        {
            title: "Last trade time",
            dataIndex: "last_timestamp",
            key: "last_timestamp",
            sorter: (a: ITraderStats, b: ITraderStats) => a.last_timestamp - b.last_timestamp,
            render: (data: any) => timestampToDate(data),
        },
    ];

    const tradesColumns = [
        {
            title: "Pool",
            dataIndex: "pool_address",
            key: "pool_address",
            render: (data: any, record: any) => {
                return (
                    <PoolTick
                        poolAddress={record.pool_address}
                        baseTokenSymbol={record.base_token_symbol}
                        quoteTokenSymbol={record.quote_token_symbol}
                        baseTokenLogo={record.base_token_image}
                        quoteTokenLogo={record.quote_token_image}
                        dex={record.dex}
                    />
                );
            },
        },
        {
            title: "Lt",
            dataIndex: "lt",
            key: "lt",
            render: (data: any) => data,
        },

        {
            title: "Date",
            dataIndex: "timestamp",
            key: "timestamp",
            render: (data: any) => timestampToDate(data),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (data: any) => formatNumber(Math.round(data)),
        },
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            render: (data: any) => "$" + data,
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (data: any) => data.toUpperCase(),
        },
        {
            title: "Dex",
            dataIndex: "dex",
            key: "dex",
            render: (data: any) => data.toUpperCase(),
        },

    ];


    console.log(stats)

    return (
        <div>
            <div className={TraderMenuStyles.AddressAndButtonBlock}>
                <div className={TraderMenuStyles.address}>{traderAddress}</div>
                <div>
                    <Button type="primary" onClick={() => showAddressInfo("")}>
                        <div className={TraderMenuStyles.goBackButtonContainer}>
                            <img
                                className={TraderMenuStyles.arrow}
                                alt={leftArrow}
                                src={leftArrow || leftArrow}
                            />
                            <div>GO BACK</div>
                        </div>
                    </Button>
                </div>
            </div>
            <div className={TraderMenuStyles.address}>Advanced statistics</div>
            <div className={TraderMenuStyles.statsWrapper}>
                <Table
                    loading={statsLoading}
                    dataSource={stats.map((x, index) => {
                        return {
                            ...x,
                            key: index,
                        };
                    })}
                    columns={traderMenuColumns}
                ></Table>
            </div>
            <div className={TraderMenuStyles.address}>Trades</div>
            <div>
                <Table
                    loading={tradesLoading}
                    dataSource={trades.map((x, index) => {
                        return {
                            ...x,
                            key: index,
                        };
                    })}
                    columns={tradesColumns}
                ></Table>
            </div>
        </div>
    );
};
