import {TON_DATA_API} from "../constants/urls";

interface ITokenStatsProps {
    tokenAddress: string;
}

export interface ITokenData {
    address: string,
    symbol: string,
    name: string,
    logo: string,
    decimals: number,
}

export const getTokenData = async ({
                                       tokenAddress,
                                   }: ITokenStatsProps): Promise<ITokenData | null> => {
    try {
        console.log(`${TON_DATA_API}/token_data/${tokenAddress}`)
        const answer = await fetch(`${TON_DATA_API}/token_data/${tokenAddress}`);
        const data = await answer.json();
        return data.result;
    } catch (error) {
        console.log("getStatsByTrader", error);
        return null;
    }
};
