import {PoolTickStyles} from "../styles/PoolTickStyles";
import {reformImageUrl} from "../utils/reformImageUrl";

interface IPoolTick {
    poolAddress: string;
    baseTokenSymbol: string;
    quoteTokenSymbol: string;
    baseTokenLogo: string;
    quoteTokenLogo: string;
    dex: string
}

export const PoolTick = ({
                             poolAddress,
                             baseTokenSymbol,
                             quoteTokenSymbol,
                             baseTokenLogo,
                             quoteTokenLogo,
                             dex
                         }: IPoolTick) => {
    return (
        <a
            href={dex === 'dedust' ? `https://dedust.io/pools/${poolAddress}` : `https://app.ston.fi/pools/${poolAddress}`}
            target="_blank"
            className={PoolTickStyles.href}
        >
            <div className={PoolTickStyles.content}>
                <div className={PoolTickStyles.imageBlock}>
                    <img src={reformImageUrl(baseTokenLogo || '')} className={PoolTickStyles.imageWrapper}/>
                    <img src={reformImageUrl(quoteTokenLogo || '')} className={PoolTickStyles.imageWrapper}/>
                </div>
                <div className={PoolTickStyles.textBlock}>
                    {baseTokenSymbol.toUpperCase()}/{quoteTokenSymbol.toUpperCase()}
                </div>
            </div>
        </a>
    );
};
