import {TON_DATA_API} from "../constants/urls";

interface IStatsProps {
    traderAddress: string;
}

export interface ITraderStats {
    trader_address: string;
    token_address: string;
    realized_pnl_usd: number;
    unrealized_pnl_usd: number;
    roi: number;
    unrealized_roi: number;
    trades_count: number;
    last_timestamp: number;
}

export const getStatsByTrader = async ({
                                           traderAddress,
                                       }: IStatsProps): Promise<ITraderStats[]> => {
    try {
        const answer = await fetch(`${TON_DATA_API}/trade_stats/${traderAddress}`);
        const data = await answer.json();
        return data.result;
    } catch (error) {
        console.log("getStatsByTrader", error);
        return [];
    }
};
