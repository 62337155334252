import {useEffect, useState} from "react";
import {getTopTraders, ITopTrader} from "../utils/getTopTraders";
import {Table} from "antd";
import {TopTradersStyles} from "../styles/TopTradersStyles";
import tonLogo from "../pics/tonLogo.png";
import {formatNumber} from "../utils/formatNumber";
import {roundTwoSymbols} from "../utils/roundTwoSymbols";
import {TraderInfoMenu} from "./TraderInfoMenu";
import {timestampToDate} from "../utils/timestampToDate";

export const TopTraders = () => {
    const [traders, setTraders] = useState<ITopTrader[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedAddress, setSelectedAddress] = useState<string>("");

    const showAddressInfo = (address: string) => {
        setSelectedAddress(address);
    };

    const topTradersColumns = [
        {
            title: "Trader",
            dataIndex: "trader_address",
            key: "trader_address",
            render: (data: any) => (
                <div
                    onClick={() => {
                        showAddressInfo(data);
                    }}
                    className={TopTradersStyles.address}
                >
                    {data}
                </div>
            ),
        },
        {
            title: "Unbounceable address",
            dataIndex: "unbounceable_trader_address",
            key: "unbounceable_trader_address",
        },
        {
            title: "Realized PNL",
            dataIndex: "realized_pnl_usd",
            key: "realized_pnl_usd",
            sorter: (a: ITopTrader, b: ITopTrader) =>
                a.realized_pnl_usd - b.realized_pnl_usd,
            render: (data: any) => "$" + formatNumber(Math.round(data)),
        },
        {
            title: "Unrealized PNL",
            dataIndex: "unrealized_pnl_usd",
            key: "unrealized_pnl_usd",
            sorter: (a: ITopTrader, b: ITopTrader) =>
                a.unrealized_pnl_usd - b.unrealized_pnl_usd,
            render: (data: any) => "$" + formatNumber(Math.round(data)),
        },

        {
            title: "ROI",
            dataIndex: "roi",
            key: "roi",
            sorter: (a: ITopTrader, b: ITopTrader) => a.roi - b.roi,
            render: (data: any) => roundTwoSymbols(data) + "%",
        },
        {
            title: "Trades count",
            dataIndex: "trades_count",
            key: "trades_count",
            sorter: (a: ITopTrader, b: ITopTrader) => a.trades_count - b.trades_count,
        },
        {
            title: "Last trade time",
            dataIndex: "last_timestamp",
            key: "last_timestamp",
            sorter: (a: ITopTrader, b: ITopTrader) => a.last_timestamp - b.last_timestamp,
            render: (data: any) => timestampToDate(data),
        },
    ];

    const getTraders = async () => {
        setLoading(true);
        setTraders([]);
        const data = await getTopTraders();
        setTraders(data);
        setLoading(false);
    };

    useEffect(() => {
        getTraders();
    }, []);

    return (
        <div className={TopTradersStyles.content}>
            <div className={TopTradersStyles.nameWrapper}>
                <img
                    className={TopTradersStyles.imageWrapper}
                    alt={tonLogo}
                    src={tonLogo || tonLogo}
                />
                <div className={TopTradersStyles.name}>Top Traders</div>
            </div>
            {selectedAddress === "" && (
                <Table
                    loading={loading}
                    pagination={false}
                    dataSource={traders.map((x, index) => {
                        return {
                            ...x,
                            key: index,
                        };
                    })}
                    columns={topTradersColumns}
                ></Table>
            )}
            {selectedAddress !== "" && (
                <TraderInfoMenu
                    traderAddress={selectedAddress}
                    showAddressInfo={showAddressInfo}
                />
            )}
        </div>
    );
};
