import {PoolTickStyles} from "../styles/PoolTickStyles";
import {useEffect, useState} from "react";
import {getTokenData, ITokenData} from "../utils/getTokenData";
import {reformImageUrl} from "../utils/reformImageUrl";

interface IData {
    tokenAddress: string;
}

export const Token = ({
                          tokenAddress
                      }: IData) => {

    const [tokenData, setTokenData] = useState<ITokenData | null>(null)

    const saveTokenData = async () => {
        setTokenData(null)
        const data = await getTokenData({tokenAddress})
        console.log(data)
        if (data) {
            setTokenData(data)
        }
    }

    useEffect(() => {
        saveTokenData()
    }, [])

    return (
        <a
            href={`https://tonscan.org/address/${tokenAddress}`}
            target="_blank"
            className={PoolTickStyles.href}
        >
            <div className={PoolTickStyles.content}>
                <div className={PoolTickStyles.imageBlock}>
                    <img
                        src={reformImageUrl(tokenData?.logo || '')}
                        className={PoolTickStyles.imageWrapper}/>
                </div>
                <div className={PoolTickStyles.textBlock}>
                    {tokenData?.symbol.toUpperCase()}
                </div>
            </div>
        </a>
    );
};
