import { css } from "@emotion/css";

export const TopTradersStyles = {
  content: css`
    margin: 52px;
  `,
  name: css`
    font-family: sans-serif;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 40px;
  `,
  imageWrapper: css`
    width: 40px;
    height: 40px;
    margin-right: 4px;
  `,
  nameWrapper: css`
    display: flex;
  `,
  address: css`
    cursor: pointer;
    font-weight: 500;
  `,
};
