export const formatNumber = (number: number) => {
  const numberStr = number.toString();
  const parts = numberStr.split(".");
  let integerPart = parts[0];
  const decimalPart = parts[1];

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return integerPart + (decimalPart ? "." + decimalPart : "");
};
